body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }

.cyb-header-btn a {
text-decoration: none;
color: white;
background-color: #007bff;
padding: 0px 0px;
border-radius: 0px;
}

.cyb-banner-section {
background-size: cover;
background-position: center;
color: white;
padding: 100px 0;
text-align: center;
}
  